import { trim } from 'ramda';

const createHtmlOpd = () =>
    `<div>
        <style>
          p {
            line-height: 28px;
            font-size: 14px;
          }
          a {
              color: #7500C0;
          }
          .checkbox-inner {
            min-width: 20px;
            margin-right: 10px;
            border: 1px solid #000000;
            width: 20px;
            height: 20px;
            border-radius: 0;
            position: relative;
            cursor: pointer;
            top: 4px;
            left: 0;
            display: inline-block;
            background-color: #ffffff;
          }
          .submitted .required .checkbox-inner {
            border-color: red;
          }
          .submitted .required input:checked + .checkbox-inner {
            border-color: #000;
          }
          .checkbox-wrap {
            display: flex;
            position: relative;
          }
          .checkbox-wrap.required .text-label:before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 18px;
            line-height: 1;
            content: "*";
          }
          .checkbox-wrap input:checked + .checkbox-inner {
            background-color: #000000;
          }
          .checkbox-wrap input {
            position: absolute!important;
            left: 0;
            z-index: 9999;
            cursor: pointer;
            opacity: 0;
            top: 0;
            bottom: 0;
            right: 0;
          }
          .checkbox-wrap input:checked + .checkbox-inner:before {
            content: '';
            left: 6px;
            top: 2px;
            width: 6px;
            height: 10px;
            transform: rotate(45deg);
            position: absolute;
            display: table;
            border: 2px solid #ffffff;
            border-top: 0;
            border-left: 0;
          }
          .text-label {
            font-weight: normal;
            font-size: 15px;
            cursor: pointer;
          }
          form + button.index__formBtn {
            color: #000;
            border: 2px solid #000;
            cursor: pointer;
          }
          .full {
              width: 90%;
          }
          .opd-html-form .text-center {
              text-align: center;
          }
        </style>
        <strong>
            <div style="text-align: center; font-size: 24px;">СОГЛАСИЕ КАНДИДАТА</div>
            <div style="text-align: center; font-size: 16px; margin: 10px 0 24px;">на обработку персоных данных</div>
        </strong>
        <div>
            Я, <input class="full text" name="name" type="text" placeholder="фамилия, имя, отчество субъекта персональных данных" required />,
            <br />
            <div class="text-center">(фамилия, имя, отчество субъекта персональных данных)</div>
            <br />
            в соответствии с п. 4 ст. 9 Федерального закона от 27.07.2006  N 152-ФЗ  "О персональных данных", зарегистрирован(а) по адресу: <br />
            <input class="full text" name="passport_address" type="text" placeholder="" required />,
            <br />
            документ, удостоверяющий личность:
            <br />
            <input class="full text" name="passport_data" type="text" placeholder="наименование документа, серия, номер, сведения о дате выдачи документа и выдавшем его органе" required />,
            <br />
            <div class="text-center">(наименование документа, серия, номер, сведения о дате выдачи документа и выдавшем его органе)</div>
        </div>
        <p style="margin-top: 30px;"><label class="checkbox-wrap required"><input name="opdPurpose" type="checkbox" data-separate-field="opdPurpose" required /><span class="checkbox-inner"></span><span class="text-label">
            в целях отбора для формирования корпоративной группы обучения студентов для зачисления в ПНИПУ,
            даю согласие ПАО «ЛУКОЙЛ» (101000, г. Москва, Сретенский бульвар, 11), ООО «ЛИКАРД» (115035, Россия, г. Москва, ул. Большая Ордынка, 3), на передачу моих персональных данных, данных ребенка:
        </span></label></p>
        <div>
            <input class="full text" name="name" type="text" placeholder="ФИО, дата рождения" required />
            <div class="text-center">ФИО, дата рождения</div>
        </div>
        <p><label class="checkbox-wrap required"><input name="opdAccept" type="checkbox" data-separate-field="opdAccept" required /><span class="checkbox-inner"></span><span class="text-label">
            а также последующую их обработку (копирование, накопление, хранение, уточнение, извлечение, использование, обезличивание, блокирование, удаление, уничтожение) автоматизированным способом и/или без использования средств автоматизации, то   есть   на   совершение   действий, предусмотренных  п.  3   ст.  3 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных".
        </span></label></p>
    </div>`
;


export const getHtmlOpd = region => createHtmlOpd();

export const getOpdValues = ({ values }) => {
  return {
      name: trim(`${values.firstName || ''} ${values.lastName || ''}`),
  };
};
