import { path, isEmpty, pick } from 'ramda';
import qs from 'qs';

export const getUtmFromSearch = search => {
    const params = qs.parse(`${search || ''}`.replace('?', ''));
    const windowParams = qs.parse(`${path(['location', 'search'], window) || ''}`.replace('?', ''));
    const utm = pick(['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'utm_tool'], params.utm_source ? params : windowParams);

    return isEmpty(utm) ? { tool: 'lukoil-azs-jobs.experium.ru' } : {
        source: utm.utm_source,
        medium: utm.utm_medium,
        campaign: utm.utm_campaign,
        term: utm.utm_term,
        content: utm.utm_content,
        tool: utm.utm_tool || ' lukoil-azs-jobs.experium.ru',
    };
};

export const getUtmTool = () => {
    return `${path(['location', 'origin'], window)}${path(['location', 'pathname'], window)}`;
};
