import React from 'react';

const Conditions = () => {
    return (
        <div className="conditions">
            {/* eslint-disable-next-line */}
            <a name="terms" id="terms" />
            <div className="row">
                <div className="col-1-4">
                    <div className="content">
                        <h2>&laquo;ЛУКОЙЛ&raquo; приглашает выпускников школ для участия в&nbsp;Конкурсе на&nbsp;поступление в&nbsp;Корпоративные Группы в&nbsp;2024 году в&nbsp;Филиал Пермского национального исследовательского ПОЛИТЕХНИЧЕСКОГО УНИВЕРСИТЕТА (ПНИПУ) в&nbsp;г.Когалым</h2>
                        <p>Оставь заявку на&nbsp;участие в&nbsp;конкурсе и&nbsp;получи все преимущества обучения в&nbsp;филиале ПНИПУ</p>
                    </div>
                </div>
                <div className="col-3-4">
                    <div className="item">
                        <h3>Гарантированное трудоустройство на&nbsp;предприятиях Группы &laquo;ЛУКОЙЛ&raquo;</h3>
                    </div>
                    <div className="item" />
                    <div className="item">
                        <h3>Проживание в&nbsp;кампусе нового поколения с&nbsp;системой &laquo;Умный Дом&raquo; и&nbsp;Face&nbsp;ID</h3>
                    </div>
                    <div className="item" />
                    <div className="item">
                        <h3>Обучение в&nbsp;одном из&nbsp;ведущих многопрофильных инженерных университетов России</h3>
                    </div>
                    <div className="item" />
                    <div className="item">
                        <h3>Компенсация оплаты проезда к&nbsp;месту проживания</h3>
                    </div>
                    <div className="item" />
                    <div className="item">
                        <h3>Развитие и&nbsp;необходимые условия для самореализации</h3>
                    </div>
                    <div className="item" />
                </div>
            </div>
        </div>
    );
}

export default Conditions;
