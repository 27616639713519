import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { any, path, propEq } from 'ramda';
import axios from 'axios';

import Vacancy from './Vacancy';
import { COMPANY, DOMAIN } from '../constants/domain';
import { getUtmFromSearch, getUtmTool } from '../utils/utm';

import qr from '../assets/img/qr.png';

const CLASSIFIER_TITLE = {
    specialist: 'Специалитет «Нефтегазовая техника и технологии»',
    bachelor: 'Бакалавриат «Нефтегазовое дело»',
};

const VACANCY_TITLE = {
    specialist: CLASSIFIER_TITLE.specialist,
    bachelor: CLASSIFIER_TITLE.bachelor,
    'specialist-success': CLASSIFIER_TITLE.specialist,
    'bachelor-success': CLASSIFIER_TITLE.bachelor,
};


const VacancyList = ({ vacancy, history }) => {
    const [modal, setModal] = useState(vacancy || null);

    const mutation = useMutation(({ vacancy, domain, values }) => {
        const url = vacancy ? `vacancy/${vacancy}/${domain}` : `domain/${domain}`;

        return axios.post(`/api/public/${url}/applicant`, {
            ...values,
            _utm: getUtmFromSearch(),
            _tool: getUtmTool(),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'ru',
            }
        })
    });
    const { isLoading, error, data } = useQuery("vacancies", () =>
        axios.get(`/api/public/vacancy`, {
            params: {
                filter: {
                    companyCode: COMPANY,
                    domainName: DOMAIN,
                },
                relations: ['cities', 'classifiers', 'domains', 'domains.company', 'domains.vacancyFormPreset']
            }
        }).then((res) => res.data)
    );

    const vacancies = (path(['items'], data) || []).filter(item => {
        const classifiers = path(['_relations', 'classifiers'], item);
        return any(propEq('name', CLASSIFIER_TITLE[modal]), classifiers || {});
    });
    const domainForm = path(['items', 0, '_relations', 'domains', 0], data) || {};

    useEffect(() => {
        if (!VACANCY_TITLE[vacancy]) {
            setModal(null);
            history.replace('/');
        }
    }, [vacancy, history]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setModal(`${modal}-success`);
            history.push(`/${modal}-success`);
            mutation.reset();
        }
    }, [mutation.isSuccess, modal, mutation, history]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setModal(`${modal}-success`);
            history.push(`/${modal}-success`);
            mutation.reset();
        }
    }, [mutation.isSuccess, modal, mutation, history]);

    const openModal = modal => {
        history.push(`/${modal}`);
        setModal(modal);
    };
    const closeModal = () => {
        history.push(`/`);
        setModal(null);
    };

    return (
        <div className="vacancy-list">
            <div className="container">
                {/* eslint-disable-next-line */}
                <a name="vacancies" id="vacancies" />
            </div>
            <div className="row">
                <div className="col-1">
                    <div className="content">
                        <div>
                            <h3>Основные условия Конкурса</h3>
                            <div className="item">
                                <div className="title">КАК СТАТЬ СТУДЕНТОМ ГРУППЫ</div>
                                <ul>
                                    <li>Выбрать профессию нефтяника!</li>
                                    <li>Подать заявку на&nbsp;обучение в&nbsp;Корпоративных группах</li>
                                    <li>
                                        Успешно сдать ЕГЭ по профильным предметам: <br />
                                        <ul>
                                            <li>Специалитет:
                                                <ol>
                                                    <li>Математика</li>
                                                    <li>Русский язык</li>
                                                    <li>на выбор: Физика, Химия, Информатика и ИКТ</li>
                                                </ol>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Пройти конкурс среди абитуриентов, поступающих в&nbsp;Корпоративные группы &laquo;ЛУКОЙЛ&raquo;</li>
                                    <li>Заключить договор с&nbsp;предприятием и&nbsp;начать обучение</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="title">ПРОГРАММЫ</div>
                                <ul>
                                    <li>Специалитет &laquo;Нефтегазовая техника и&nbsp;технологии&raquo; (срок обучения 5,5&nbsp;лет)</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="title">ПОДРОБНЕЕ ОЗНАКОМИТЬСЯ <br /> С&nbsp;ПРОЕКТОМ МОЖНО ПО&nbsp;QR-КОДУ</div>
                                <div className="img">
                                    <img src={qr} alt="qr"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="controls">
                        <button className="btn btn-outline btn-black" onClick={() => openModal('specialist')}>
                            Откликнуться на&nbsp;направление: Специалитет &laquo;Нефтегазовая техника и&nbsp;технологии&raquo;
                        </button>
                    </div>
                </div>
            </div>
            <Modal isOpen={!!modal} onRequestClose={closeModal} ariaHideApp={false}>
                <div className="modal-header">
                    <h3>{ VACANCY_TITLE[modal] || '' }</h3>
                    <button className="btn-close" onClick={closeModal}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 8L8 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 8L24 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">
                    { CLASSIFIER_TITLE[modal] ? (
                        error || (!isLoading && !vacancies.length) ? (
                            <p style={{ 'textAlign': 'center' }}>
                                { error ? (
                                    'Не удалось загрузить вакансию, попробуйте обновить страницу'
                                ) : (
                                    'Нет доступных вакансий'
                                )}
                            </p>
                        ) : (
                            isLoading ? 'Загрузка вакансии...' : (
                                <Vacancy
                                    mutation={mutation}
                                    vacancy={vacancies[0]}
                                    domain={domainForm}
                                />
                            )
                        )
                    ) : (
                        <h2>ВАША АНКЕТА ПЕРЕДАНА АДМИНИСТРАТОРУ КОНКУРСА</h2>
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default VacancyList;
